import React from 'react';
import './ProjectCard.scss';

class ProjectCard extends React.Component{

    render(){

        return (
            
                <li>
                    <div className="flex">
                        <div className="flex">
                            <div className="line">
                                <p className="text-bold title-area line">
                                    <a href={this.props.url} target="_blank" className="projectTitle">{this.props.title}</a>
                                </p>
                                <p className="text-regular experience-text line">
                                    <span className="subTitle text-regular">{this.props.subTitle}</span>  <span className="duration">{this.props.year}</span>
                                </p>

                                <p className="text-regular experience-text w-100 line">
                                    {this.props.description}
                                </p>
                                <div className="w-100 row skills">
                                    {this.props.tags?.map((tag, index) => {
                                        return (
                                            <small key={index} className="flex skill text-regular">
                                                {tag}
                                            </small>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    
                    </div>
                </li>
            
                
        )
        
    }
}

export default ProjectCard;