import React from 'react';
import './ShowMoreLessButton.scss';

export default function ShowMoreLessButton({ collapsed, className, ...props }) {
  return (
    <button className={`show-more-less ${className}`} {...props}>
      {collapsed ? 'SHOW MORE' : 'SHOW LESS'}
    </button>
  );
}
