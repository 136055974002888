import React from 'react';
import './ExperienceCard.scss';
import PeriodDuration from '../../PeriodDuration/PeriodDuration';

export default function ExperienceCard(props) {
  return (
    <li>
      <div className="line">
        <p className="text-bold title-area line">{props.title}</p>
        <p className="text-regular experience-text line">
          <span className="company">{props.company}</span>{' '}
          <PeriodDuration
            startMonth={props.startMonth}
            startYear={props.startYear}
            endMonth={props.endMonth}
            endYear={props.endYear}
          />
        </p>

        <p
          className="text-regular experience-text w-100 line"
          dangerouslySetInnerHTML={{
            __html: props.description,
          }}
        ></p>
        <div className="w-100 row skills row-tags">
          {props.skills?.map((skill, index) => {
            return (
              <small key={index} className="flex skill text-regular">
                {skill}
              </small>
            );
          })}
        </div>
      </div>
    </li>
  );
}
