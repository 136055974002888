import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.scss';
import App from './App';
import { BrowserRouter as Router, Switch, Redirect, Route } from 'react-router-dom';
import ProfilePage from './components/ProfilePage/ProfilePage';
import GenericNotFound from './components/GenericNotFound/GenericNotFound';

ReactDOM.render(
  <Router>
    <App>
      <Switch>
        <Route exact={true} path="/:id" component={ProfilePage} />
        <Route path="/errors/404" component={GenericNotFound} />
        <Route path="*" render={() => <Redirect to="/errors/404" />} />
      </Switch>
    </App>
  </Router>,
  document.getElementById('root'),
);
