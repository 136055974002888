import React from 'react';
import './EducationCard.scss';
import PeriodDuration from '../../PeriodDuration/PeriodDuration';

export default function EducationCard(props) {
  return (
    <li>
      <div className="line">
        <p className="text-bold title-area line">{props.title}</p>
        <p className="text-regular education-text line">
          <span className="company">{props.subTitle}</span>{' '}
          <PeriodDuration
            startMonth={props.startMonth}
            startYear={props.startYear}
            endMonth={props.endMonth}
            endYear={props.endYear}
          />
        </p>
      </div>
    </li>
  );
}
