import React from 'react'
import './TopFiveCard.scss';
import JSIcon from '../../assets/icons/js.svg';
import JavaIcon from '../../assets/icons/java.svg';
import CodeIcon from '../../assets/icons/code.svg';
import AngularIcon from '../../assets/icons/angular.svg';
import NodeJSIcon from '../../assets/icons/nodejs.svg';
import PythonIcon from '../../assets/icons/python.svg';
import PhpIcon from '../../assets/icons/php.svg';
import ReactIcon from '../../assets/icons/react.svg';
import VueIcon from '../../assets/icons/vue.svg';
import MysqlIcon from '../../assets/icons/mysql.svg';
import CSharpIcon from '../../assets/icons/csharp.svg';
import SqlIcon from '../../assets/icons/sql.svg';
import TypeScriptIcon from '../../assets/icons/typeScriptIcon.svg';
import GoLangIcon from '../../assets/icons/goLangIcon.svg';
import RubyIcon from '../../assets/icons/rubyIcon.svg';
import rubyOnRailsIcon from '../../assets/icons/rubyOnRailsIcon.svg';
// import cPlusPlusIcon from '../../assets/icons/cPlusPlusIcon.svg';
import AndroidIcon from '../../assets/icons/Android.svg';
import FlutterIcon from '../../assets/icons/Flutter.svg';
import FirebaseIcon from '../../assets/icons/Firebase.svg';
import DockerIcon from '../../assets/icons/Docker.svg';
import KubernetesIcon from '../../assets/icons/Kubernetes.svg';
import IOSIcon from '../../assets/icons/Apple.svg';
import KotlinIcon from '../../assets/icons/Kotlin.svg';
import SwiftIcon from '../../assets/icons/SwiftIcon.svg';
import CPlusPlus from '../../assets/icons/CppIcon.svg';
import Ionic from '../../assets/icons/ionicIcon.svg';
class TopFiveCard extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            expertises: []
        }
    }
    componentDidMount(){

        function sortByMainSkill(arr) {
            var result1 = [], result2 = [];
            for (var i = 0; i < arr.length; i++) {
                if (!arr[i].hasOwnProperty("mainSkill") || arr[i]["mainSkill"] === false) {
                    result2.push(arr[i]);
                } else {
                    result1.push(arr[i]);
                }
            }
            var result = result1.concat(result2);
            return result;
        }

        this.setState({
            expertises: sortByMainSkill(this.props.expertises) ? sortByMainSkill(this.props.expertises)?.slice(0,5) : []
        })
    }

    skillIcon(skill) {
        switch (skill) {
            case 'JavaScript':
                return JSIcon;
                break;
            case 'Javascript':
                return JSIcon;
                break;
            case 'TypeScript':
                return TypeScriptIcon;
                break;
            case 'Typescript':
                return TypeScriptIcon;
                break;
            case 'Golang':
                return GoLangIcon;
                break;
            case 'GoLang':
                return GoLangIcon;
                break;
            case 'Rails':
                return rubyOnRailsIcon;
                break;
            case 'Ruby':
                return RubyIcon;
                break;
            case 'Java':
                return JavaIcon;
                break;
            case 'Kotlin':
                return KotlinIcon;
                break;
            case 'React':
                return ReactIcon;
                break;        
            case 'ReactJS':
                return ReactIcon;
                break;
            case 'Angular':
                return AngularIcon;
                break;
            case 'AngularJS':
                return AngularIcon;
                break;
            case 'Python':
                return PythonIcon;
                break;
            case 'Node.js':
                return NodeJSIcon;
                break;
            case 'PHP':
                return PhpIcon;
                break;
            case 'VueJS':
                return VueIcon;
                break;
            case 'MySQL':
                return MysqlIcon;
                break;
            case 'SQL':
                return SqlIcon;
                break;
            case 'C#':
                return CSharpIcon;
                break;
            case 'iOS':
                return IOSIcon;
                break;
            case 'Android':
                return AndroidIcon;
                break;
            case 'Flutter':
                return FlutterIcon;
                break;
            case 'Firebase':
                return FirebaseIcon;
                break;
            case "Docker":
                return DockerIcon;
                break;
            case "Kubernetes":
                return KubernetesIcon;
                break;
            case "Swift":
                return SwiftIcon;
                break;
            case "C++":
                return CPlusPlus;
            case "Ionic":
                return Ionic;
            default:
                return CodeIcon;
                break;
        }
    }

    render(){

        let expertises;

        if(this.state.hasOwnProperty('expertises') && this.state.expertises.length > 0) {

            expertises = <div className="trio-card cut-card">
                        <div className="container container-expertises">
                            <h1 className="text-left expertise-title text-semibold">Top 5 Expertise</h1>
                            <ul>
                                {this.state.expertises?.map( (expertise, index) => {
                                    return (
                                        <li key={index} className="skill-item">
                                            <div className="flex">
                                                <img src={this.skillIcon(expertise.skill)} className="language-icon" />
                                                <div>
                                                    <p className="text-bold title-area">
                                                        {expertise.skill}
                                                    </p>
                                                    <p className="text-regular experience-text exp-desc exp-top-five">
                                                        {expertise.experience}
                                                    </p>
                                                </div>
                                            
                                            </div>
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>
                    </div>
        }else {
            expertises = '';
        }

        return (
            expertises
        )
        
    }
}

export default TopFiveCard;