import React from 'react';
import './ProjectsSection.scss';
import "react-multi-carousel/lib/styles.css";
import ProjectCard from './ProjectCard/ProjectCard';
import ShowMoreLessButton from '../ShowMoreLessButton/ShowMoreLessButton';

class ProjectsSection extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            projects: [],
            loadMore: false
        }
    }

    componentDidMount() {
        this.setState({
            projects: this.props.projects ? this.props.projects : []
        })

    }

    toggleButton() {
        this.setState({
            loadMore: !this.state.loadMore
        })
    }
    
    render(){

        let projectsSection;

        if(this.state.hasOwnProperty('projects') && this.state.projects.length > 0) {

            projectsSection = <div className="trio-card remove-border-top">
                    <div className="w-100">
                        <h1 className="text-left expertise-title text-semibold">Projects</h1> 

                        <ul className="expertise-list">
                            {this.state.projects?.slice(0, this.state.loadMore ? this.state.projects.length : 1).map( (project, index) => {
                                return (
                                    <div key={index}>
                                        <ProjectCard
                                            key={index}
                                            title={project.title}
                                            subTitle={project['sub-title']}
                                            year={project.year}
                                            description={project.description}
                                            tags={project.tags}
                                            url={project.url}
                                        />
                                    </div>
                                )
                            })}
                        </ul>

                        {this.state.projects.length > 1 ? (
                            <ShowMoreLessButton collapsed={!this.state.loadMore} onClick={() => this.toggleButton()} />
                        ) : ''}
                    </div>
                </div>
        }else {
            projectsSection = ''
        }

        return (
            
            projectsSection
                
        )
        
    }
}

export default ProjectsSection;