import './ProfilePage.scss';
import ProfileTopCard from '../ProfileTopCard/ProfileTopCard';
import TopFiveCard from '../TopFiveCard/TopFiveCard';
import VideosSection from '../VideosSection/VideosSection';
import ExpertiseSection from '../ExpertiseSection/ExpertiseSection';
import ProjectsSection from '../ProjectsSection/ProjectsSection';
import ExperienceSection from '../ExperienceSection/ExperienceSection';
import EducationSection from '../EducationSection/EducationSection';
import RightMenuCard from '../RightMenuCard/RightMenuCard';
import TalentService from '../../services/TalentService';

import { BrowserRouter as Redirect } from 'react-router-dom';
import React from 'react';
import Footer from '../../components/Footer/Footer';

class ProfilePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      talent: null,
      isLoading: true,
      ref: null,
    };
  }

  componentDidMount() {
    const {
      match: { params },
    } = this.props;
    if (params) {
      TalentService.getTalentById(params.id).then(
        (res) => {
          this.setState({
            isLoading: false,
            talent: res.data,
            content: null,
          });
        },
        (err) => {
          this.setState({
            isLoading: false,
          });
          this.props.history.push({
            pathname: '/errors/404',
          });
        },
      );
    }
  }

  render() {
    let content;
    let loading;

    if (!this.state.isLoading) {
      content = (
        <div>
          <div className="container">
            <div className="row">
              <div className="col-lg-8 column-section">
                <section id="bio">
                  <ProfileTopCard
                    state={this.state.talent?.state}
                    country={this.state.talent?.country}
                    name={this.state.talent?.user?.name}
                    image={this.state.talent?.user?.providerAvatar}
                    headline={this.state.talent?.preferredRole[0]}
                    bio={this.state.talent?.user?.bio}
                  />
                </section>

                <section id="videos" className="margin-top-15">
                  <VideosSection videos={this.state.talent?.videos} />
                </section>

                <div className="margin-top-15">
                  <section id="expertise">
                    <ExpertiseSection expertises={this.state.talent?.skills} />
                  </section>
                  <section id="projects">
                    <ProjectsSection projects={this.state.talent?.projects} />
                  </section>
                </div>

                <div className="margin-top-15">
                  <section id="workexperience">
                    <ExperienceSection experiences={this.state.talent?.workExperience} />
                  </section>
                </div>

                <div className="margin-top-15">
                  <section id="education">
                    <EducationSection educations={this.state.talent?.education} />
                  </section>
                </div>
              </div>

              <div id="column-fixed" className="col-lg-4 column-section">
                <div id="affix" className="affix">
                  <TopFiveCard expertises={this.state.talent?.skills} />
                  <div className="display-none">
                    <RightMenuCard />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Footer />
        </div>
      );
    } else {
      loading = (
        <div className="loading-bar">
          <div className="progress">
            <div className="progress-bar-custom"></div>
          </div>
        </div>
      );
    }

    return (
      <div>
        {loading}
        {content}
      </div>
    );
  }
}

export default ProfilePage;
