
import './GenericNotFound.scss';
import React from 'react';

import NotFoundEmoticon from '../../assets/imgs/404.png';
class GenericNotFound extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: true
    }
  }

  componentDidMount() {
   
  }

  render() {

    return (
      <div className="notFoundImage">
        <img src={NotFoundEmoticon} />
        <p className="errorMessage">Woah! The page you are looking for doesn’t exist or it seems the data you are looking for is no longer available.</p>
      </div>
    );
  }
}

export default GenericNotFound;
