import React from 'react';
import './VideoCard.scss';
import { Link } from "react-router-dom";

class VideoCard extends React.Component{


    render(){

        return (
            <a href={this.props.url} target="_blank">
                <div className="video-card">
                    <h1 className="text-left video-title text-semibold">{this.props.title}</h1> 
                    <div className="video-content">
                        <img src={this.props.thumbnail} className="video-img" />
                        <div className="video-details">
                            <p className="text-regular video-description">
                            {this.props.description}
                            </p>
                        </div>
                    </div>
                </div>
            </a>
                
        )
        
    }
}

export default VideoCard;