import axios from 'axios';


class TalentService {
    
    getTalentById(id){
        return axios.get(process.env.REACT_APP_API_URI + "talents/" + id)
    }
}

export default new TalentService();