import React from 'react'
import './Footer.scss'
import Logo from '../../assets/imgs/logo_trio_blue.svg';
import { Link } from "react-router-dom";

class Footer extends React.Component{


    render(){

        return (
            
            <footer>
                <div className="container">
                    <img src={Logo} className="logo" />
                </div>
            </footer>
                
        )
        
    }
}

export default Footer;