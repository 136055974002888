import React from 'react';
import './PeriodDuration.scss';
import { getDuration } from '../../utils/Date';

export default function PeriodDuration({ startMonth, startYear, endMonth, endYear }) {
  const startPeriod = `${startMonth?.substr(0, 3)} ${startYear}`;
  const endPeriod = endYear ? `${(endMonth || '').substr(0, 3)} ${endYear}` : 'Present';
  const duration = getDuration(
    startMonth ? startMonth : 'January',
    startYear,
    endMonth ? endMonth : 'January',
    endYear,
  );

  return (
    <span className="period-duration">
      {startPeriod} – {endPeriod} • {duration}
    </span>
  );
}
