import React from 'react'
import './RightMenuCard.scss';
import Scrollspy from 'react-scrollspy'

class RightMenuCard extends React.Component{

    componentDidMount() {
    }

    render(){

        return (
            
            <div className="trio-card trio-card-right-menu">
                <div className="container container-expertises">
                
                    <Scrollspy items={ ['bio', 'videos', 'expertise', 'projects', 'workexperience', 'education'] } currentClassName="is-current">
                        <li className="menu-item">
                            <a href="#bio" className="text-regular menu-option">
                                Developer Bio
                            </a>
                        </li>
                        <li className="menu-item">
                            <a href="#videos" className="text-regular menu-option">
                                Videos
                            </a>
                        </li>
                        <li className="menu-item">
                            <a href="#expertise" className="text-regular menu-option">
                                Expertise
                            </a>
                        </li>
                        <li className="menu-item">
                            <a href="#projects" className="text-regular menu-option">
                                Projects
                            </a>
                        </li>
                        <li className="menu-item">
                            <a href="#workexperience" className="text-regular menu-option">
                                Work Experience
                            </a>
                        </li>
                        <li className="menu-item">
                            <a href="#education" className="text-regular menu-option">
                                Education
                            </a>
                        </li>
                        {/* <li className="menu-item">
                            <a href="#github" className="text-regular menu-option">
                                GitHub Insights
                            </a>
                        </li> */}
                    </Scrollspy>
                </div>
            </div>
        )
        
    }
}

export default RightMenuCard;