import React, { useEffect, useState, useRef } from 'react';
import './ProfileTopCard.scss';
import AvatarPlaceholder from '../../assets/imgs/avatar-placeholder.jpg';
import GlobalIcon from '../../assets/icons/world.svg';
import ShowMoreLessButton from '../ShowMoreLessButton/ShowMoreLessButton';

export default function ProfileTopCard(props) {
  const [isDescriptionOverflowing, setIsDescriptionOverflowing] = useState(false);
  const [isDescriptionCollapsed, setIsDescriptionCollapsed] = useState(true);
  const descriptionRef = useRef();

  useEffect(() => {
    const { clientHeight, scrollHeight } = descriptionRef.current;
    const isDescriptionOverflowing = clientHeight < scrollHeight;
    setIsDescriptionOverflowing(isDescriptionOverflowing);
  }, []);

  function onShowMore() {
    setIsDescriptionCollapsed((isDescriptionCollapsed) => !isDescriptionCollapsed);
  }

  return (
    <div className="trio-card trio-top-card">
      <div className="container">
        <div className="box-item">
          <img src={props.image ? props.image : AvatarPlaceholder} className="logoProfile" />
        </div>
        <div className="profile-info">
          <h1 className="text-left developer-name text-semibold">{props.name}.</h1>
          <h3 className="text-left developer-headline text-regular">{props.headline ? props.headline : ''}</h3>
          <p className="location">
            <img className="location-icon" src={GlobalIcon} />
            <span className="text-regular location-text">
              {props.state}, {props.country}
            </span>
          </p>

          <p
            ref={descriptionRef}
            className={`description ${isDescriptionCollapsed && 'description-collapsed'}`}
            dangerouslySetInnerHTML={{ __html: props.bio }}
          ></p>

          {isDescriptionOverflowing && (
            <ShowMoreLessButton className="mt-3" collapsed={isDescriptionCollapsed} onClick={onShowMore} />
          )}
        </div>
      </div>
    </div>
  );
}
