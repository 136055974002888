const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const getMonthIndexFromMonthStr = (monthStr) => {
  return monthStr === 'Present' ? new Date().getMonth() : MONTHS.indexOf(monthStr);
};

const getMonthDifference = (startDate, endDate) => {
  return endDate.getMonth() - startDate.getMonth() + 12 * (endDate.getFullYear() - startDate.getFullYear());
};

export const getDuration = (startMonthStr, startYear, endMonthStr, endYear) => {
  const startMonth = getMonthIndexFromMonthStr(startMonthStr);

  if (startMonth === -1 || !startYear) {
    return '';
  }

  const endMonth = endMonthStr ? getMonthIndexFromMonthStr(endMonthStr) : new Date().getMonth();
  endYear = endYear || new Date().getFullYear();

  const monthsDiff = getMonthDifference(new Date(startYear, startMonth), new Date(endYear, endMonth));
  const yearsDiff = Math.floor(monthsDiff / 12);
  const remainingMonths = monthsDiff % 12;

  let duration = '';

  if (yearsDiff === 1) {
    duration += `${yearsDiff} yr `;
  } else if (yearsDiff > 1) {
    duration += `${yearsDiff} yrs `;
  }

  if (remainingMonths === 1) {
    duration += `${remainingMonths} mo`;
  } else if (remainingMonths > 1) {
    duration += `${remainingMonths} mos`;
  }

  return duration;
};
