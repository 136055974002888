import React from 'react';
import './VideosSection.scss';
import "react-multi-carousel/lib/styles.css";
import VideoCard from './VideoCard/VideoCard';
import Carousel from "react-multi-carousel";
import VideoImag from '../../assets/imgs/video.png';
import { Image } from "semantic-ui-react";

class ExpertiseSection extends React.Component{

    constructor(props){
        super(props);

        this.state = {
            videos: []
        }
    }

    componentDidMount() {
        this.setState({
            videos: this.props.videos ? this.props.videos : []
        })

    }
    render(){

        const responsive = {
            desktop: {
                breakpoint: { max: 3000, min: 1024 },
                items: 3,
                slidesToSlide: 3,
            },
            tablet: {
              breakpoint: { max: 1024, min: 464 },
              items: 2,
              slidesToSlide: 2,
            },
            mobile: {
              breakpoint: { max: 464, min: 0 },
              slidesToSlide: 1,
              items: 1,
            }
        };
        let videosSection;

        if(this.state.hasOwnProperty('videos') && this.state.videos.length > 0) {

            videosSection =  <div className="trio-card trio-videos-card">
                <div className="w-100">
                    <h1 className="text-left expertise-title text-semibold">Videos</h1> 
                    <Carousel
                        ssr={false} // means to render carousel on server-side.
                        infinite={true}
                        itemClass="image-item"
                        containerClass="carousel-container"
                        responsive={responsive}
                        transitionDuration={500}
                    >
                            {this.state.videos.map( (video, index) => {
                                return (
                                    <div key={index}>
                                        <VideoCard
                                            title={video.title}
                                            description={video.description}
                                            url={video.url}
                                            thumbnail={video.thumbnail}
                                        
                                        />
                                    </div>
                                )
                            })}
                        </Carousel>
                    </div>
                </div>
        }else {
            videosSection = '';
        }

        return (
            
            videosSection
                
        )
        
    }
}

export default ExpertiseSection;