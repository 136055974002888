import React from 'react';
import './EducationSection.scss';
import "react-multi-carousel/lib/styles.css";
import EducationCard from './EducationCard/EducationCard';
import ShowMoreLessButton from '../ShowMoreLessButton/ShowMoreLessButton';

class EducationSection extends React.Component{

    constructor(props){
        super(props); 
        this.state = {
            educations: [],
            loadMore: false
        }
    }

    componentDidMount(){
        this.setState({
            educations: this.props.educations ? this.props.educations : []
        })
    }


    toggleButton() {
        this.setState({
            loadMore: !this.state.loadMore
        })
    }

    render(){

        let educationSection;

        if(this.state.hasOwnProperty('educations') && this.state.educations.length > 0) {
            educationSection = <div className="trio-card">
                                    <div className="w-100">
                                        <h1 className="text-left education-title text-semibold">Education</h1> 

                                        <ul className="education-list">
                                            {this.state.educations?.slice(0, this.state.loadMore ? this.state.educations.length : 3).map( (education, index) => {
                                                return (
                                                    <div key={index}>
                                                        <EducationCard
                                                            key={index}
                                                            title={education.university}
                                                            subTitle={education.degree}
                                                            startMonth={education.start?.month}
                                                            startYear={education.start?.year}
                                                            endMonth={education.end?.month}
                                                            endYear={education.end?.year}
                                                        />
                                                    </div>
                                                )
                                            })}
                                        </ul>

                                        {this.state.educations.length > 3 ? (
                                            <ShowMoreLessButton collapsed={!this.state.loadMore} onClick={() => this.toggleButton()} />
                                        ) : ''}
                                    </div>
                                </div>
        }else {
            educationSection = '';
        }

        return (
            educationSection
        )
        
    }
}

export default EducationSection;