import React from 'react';
import './ExperienceSection.scss';
import "react-multi-carousel/lib/styles.css";
import ExperienceCard from './ExperienceCard/ExperienceCard';
import ShowMoreLessButton from '../ShowMoreLessButton/ShowMoreLessButton';

class ExperienceSection extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            experiences: [],
            loadMore: false
        }
    }

    componentDidMount(){
        this.setState({
            experiences: this.props.experiences ? this.props.experiences : []
        })
    }

    toggleButton() {
        this.setState({
            loadMore: !this.state.loadMore
        })
    }
    
    render(){

        let experiences;
        if(this.state.hasOwnProperty('experiences') && this.state.experiences.length > 0) {
            experiences = <div className="trio-card remove-border-bottom">
                            <div className="w-100">
                                <h1 className="text-left experience-title text-semibold">Experience</h1> 

                                <ul className="experience-list">
                                    {this.state.experiences?.slice(0, this.state.loadMore ? this.state.experiences.length : 3).map( (experience, index) => {
                                        return (
                                            <div key={index}>
                                                <ExperienceCard
                                                    key={index}
                                                    title={experience.title}
                                                    company={experience.company}
                                                    current={experience.current}
                                                    duration={experience.duration}
                                                    startMonth={experience.start?.month}
                                                    startYear={experience.start?.year}
                                                    endMonth={experience.end?.month}
                                                    endYear={experience.end?.year}
                                                    description={experience.description}
                                                    skills={experience.skills}
                                                />
                                            </div>
                                        )
                                    })}
                                </ul>

                                {this.state.experiences.length > 3 ? (
                                    <ShowMoreLessButton collapsed={!this.state.loadMore} onClick={() => this.toggleButton()} />
                                ) : ''}

                            </div>
                        </div>
        }else {
            experiences = '';
        }

        return (
            
            experiences
                
        )
        
    }
}

export default ExperienceSection;