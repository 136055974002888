import React from 'react';
import './ExpertiseCard.scss';
import JSIcon from '../../../assets/icons/js.svg';
import JavaIcon from '../../../assets/icons/java.svg';
import CodeIcon from '../../../assets/icons/code.svg';
import AngularIcon from '../../../assets/icons/angular.svg';
import NodeJSIcon from '../../../assets/icons/nodejs.svg';
import PythonIcon from '../../../assets/icons/python.svg';
import PhpIcon from '../../../assets/icons/php.svg';
import ReactIcon from '../../../assets/icons/react.svg';
import VueIcon from '../../../assets/icons/vue.svg';
import MysqlIcon from '../../../assets/icons/mysql.svg';
import CSharpIcon from '../../../assets/icons/csharp.svg';
import SqlIcon from '../../../assets/icons/sql.svg';
import TypeScriptIcon from '../../../assets/icons/typeScriptIcon.svg';
import GoLangIcon from '../../../assets/icons/goLangIcon.svg';
import RubyIcon from '../../../assets/icons/rubyIcon.svg';
import rubyOnRailsIcon from '../../../assets/icons/rubyOnRailsIcon.svg';
// import cPlusPlusIcon from '../../../assets/icons/cPlusPlusIcon.svg';
import AndroidIcon from '../../../assets/icons/Android.svg';
import FlutterIcon from '../../../assets/icons/Flutter.svg';
import FirebaseIcon from '../../../assets/icons/Firebase.svg';
import DockerIcon from '../../../assets/icons/Docker.svg';
import KubernetesIcon from '../../../assets/icons/Kubernetes.svg';
import IOSIcon from '../../../assets/icons/Apple.svg';
import KotlinIcon from '../../../assets/icons/Kotlin.svg';
import SwiftIcon from '../../../assets/icons/SwiftIcon.svg';
import CPlusPlus from '../../../assets/icons/CppIcon.svg';
import Ionic from '../../../assets/icons/ionicIcon.svg';

class ExpertiseCard extends React.Component{

    skillIcon(skill) {
        switch (skill) {
            case 'JavaScript':
                return JSIcon;
                break;
            case 'Javascript':
                return JSIcon;
                break;
            case 'TypeScript':
                return TypeScriptIcon;
                break;
            case 'Typescript':
                return TypeScriptIcon;
                break;
            case 'Golang':
                return GoLangIcon;
                break;
            case 'GoLang':
                return GoLangIcon;
                break;
            case 'Rails':
                return rubyOnRailsIcon;
                break;
            case 'Ruby':
                return RubyIcon;
                break;
            case 'Java':
                return JavaIcon;
                break;
            case 'Kotlin':
                return KotlinIcon;
                break;
            case 'React':
                return ReactIcon;
                break;        
            case 'ReactJS':
                return ReactIcon;
                break;
            case 'Angular':
                return AngularIcon;
                break;
            case 'AngularJS':
                return AngularIcon;
                break;
            case 'Python':
                return PythonIcon;
                break;
            case 'Node.js':
                return NodeJSIcon;
                break;
            case 'PHP':
                return PhpIcon;
                break;
            case 'VueJS':
                return VueIcon;
                break;
            case 'MySQL':
                return MysqlIcon;
                break;
            case 'SQL':
                return SqlIcon;
                break;
            case 'C#':
                return CSharpIcon;
                break;
            case 'iOS':
                return IOSIcon;
                break;
            case 'Android':
                return AndroidIcon;
                break;
            case 'Flutter':
                return FlutterIcon;
                break;
            case 'Firebase':
                return FirebaseIcon;
                break;
            case "Docker":
                return DockerIcon;
                break;
            case "Kubernetes":
                return KubernetesIcon;
                break;
            case "Swift":
                return SwiftIcon;
                break;
            case "C++":
                return CPlusPlus;
                break;
            case "Ionic":
                return Ionic;
            default:
                return CodeIcon;
                break;
        }
    }

    render(){

        return (
            
            <li>
                <div className="flex">
                    <div className="flex">
                        <img src={this.skillIcon(this.props.title)} className="language-icon" />
                    </div>
                    <div className="flex">
                        <div className="line">
                            <p className="text-bold title-area line">
                                {this.props.title}

                                <span className="text-regular experience-text duration line">
                                    {this.props.exp}
                                </span>
                            </p>

                            <p className="text-regular experience-text w-100 line">
                                {this.props.description}
                            </p>
                            <div className="w-100 row skills row-tags">
                                {this.props.skills?.map((skill, index) => {
                                    return (
                                        <small key={index} className="flex skill text-regular">
                                            {skill}
                                        </small>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                   
                </div>
            </li>
                
        )
        
    }
}

export default ExpertiseCard;