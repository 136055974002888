import React from 'react';
import './ExpertiseSection.scss';
import "react-multi-carousel/lib/styles.css";
import ExpertiseCard from './ExpertiseCard/ExpertiseCard';
import ShowMoreLessButton from '../ShowMoreLessButton/ShowMoreLessButton';

class ExpertiseSection extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            expertises: [],
            loadMore: false
        }
    }

    componentDidMount(){
        this.setState({
            expertises: this.props.expertises ? this.props.expertises : []
        })
    }

    toggleButton() {
        this.setState({
            loadMore: !this.state.loadMore
        })
    }
    
    render(){

        let expertises;
        if(this.state.hasOwnProperty('expertises') && this.state.expertises.length > 0) {
            expertises = <div className="trio-card remove-border-bottom">
                <div className="w-100">
                    <h1 className="text-left expertise-title text-semibold">Expertise</h1> 

                    <ul className="expertise-list">
                        {this.state.expertises?.slice(0, this.state.loadMore ? this.state.expertises.length : 3).map( (skill, index) => {
                            return (
                                <div key={index}>
                                    <ExpertiseCard
                                        key={index}
                                        img={skill.img}
                                        title={skill.skill}
                                        exp={skill.experience}
                                        description={skill.description}
                                        skills={skill.tags}
                                    />
                                </div>
                            )
                        })}
                    </ul>
                    
                    {this.state.expertises.length > 3 ? (
                        <ShowMoreLessButton collapsed={!this.state.loadMore} onClick={() => this.toggleButton()} />
                    ) : ''}

                </div>
            </div>
        }else {
            expertises = '';
        }

        return (
            
            expertises
                
        )
        
    }
}

export default ExpertiseSection;